/* eslint-disable import/no-cycle */
import { type NavigationItem, type NavigationItemParent } from '../../../../shared/types';
import { getTrackingLabel } from '../../../helper/trackNavigation';
import { BurgerNavigationItem } from '../BurgerNavigationItem/BurgerNavigationItem';
import { BurgerNavigationSelfReferrerItem } from '../BurgerNavigationSelfReferrerItem';

type BurgerNavigationGroupProps = {
    readonly items: NavigationItem[] | undefined;
    readonly level: number;
    readonly parent?: NavigationItemParent;
    readonly toggleFunction: (level: number, string?: string) => void;
    readonly className?: string;
    readonly parentTrackingLabel?: string;
};

const BurgerNavigationGroup = ({
    className,
    items,
    level,
    parent,
    parentTrackingLabel,
    toggleFunction,
}: BurgerNavigationGroupProps): JSX.Element => {
    return (
        <ul className={className} data-level={level}>
            {level > 0 && level <= 3 && (
                <BurgerNavigationItem
                    key={parent?.id}
                    item={parent as NavigationItem}
                    level={level}
                    toggleFunction={() => {
                        return null;
                    }}
                    isNavigationTitle
                    trackingLabel={getTrackingLabel(parent?.title as string, parentTrackingLabel)}
                />
            )}
            {level !== 1 && (
                <BurgerNavigationSelfReferrerItem
                    parent={parent}
                    level={level}
                    toggleFunction={toggleFunction}
                    trackingLabel={getTrackingLabel(parent?.title as string, parentTrackingLabel)}
                />
            )}
            {items?.map(item => (
                <BurgerNavigationItem
                    key={item.id}
                    level={level}
                    item={item}
                    toggleFunction={toggleFunction}
                    parentId={parent?.id}
                    trackingLabel={getTrackingLabel(item?.title as string, parentTrackingLabel)}
                />
            ))}
        </ul>
    );
};

export { BurgerNavigationGroup };
