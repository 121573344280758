import { defineMessages, useIntl } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type NavigationItem, type NavigationItemParent } from '../../../../shared/types';
import { type HeaderContext } from '../../../types';
// eslint-disable-next-line import/no-cycle
import { BurgerNavigationItem } from '../BurgerNavigationItem/BurgerNavigationItem';

const messages = defineMessages({
    selfReferrerTitle: {
        defaultMessage: 'Alles in {title}',
    },
    selfReferrerTitleExperiences: {
        defaultMessage: 'Alle Erlebnisse',
    },
});

type BurgerNavigationSelfReferrerItemProps = {
    readonly level: number;
    readonly toggleFunction: (level: number, string?: string) => void;
    readonly parent?: NavigationItemParent;
    readonly trackingLabel?: string;
};

const BurgerNavigationSelfReferrerItem = ({
    level,
    parent,
    toggleFunction,
    trackingLabel,
}: BurgerNavigationSelfReferrerItemProps): JSX.Element | null => {
    const intl = useIntl();
    const { tenantConfig }: HeaderContext = useFragmentContext();

    function getSelfReferrerTitle(navigationGroup: NavigationItemParent): string {
        return navigationGroup.id === tenantConfig.category
            ? intl.formatMessage(messages.selfReferrerTitleExperiences)
            : intl.formatMessage(messages.selfReferrerTitle, { title: navigationGroup.title });
    }

    if (parent && parent.title && parent.uri) {
        const parentItem = {
            ...parent,
            title: getSelfReferrerTitle(parent),
        };

        return (
            <BurgerNavigationItem
                key={parent.id}
                item={parentItem as NavigationItem}
                level={level}
                toggleFunction={toggleFunction}
                isSelfReferrer
                trackingLabel={trackingLabel}
            />
        );
    }

    return null;
};

export { BurgerNavigationSelfReferrerItem };
