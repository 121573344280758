import { memo } from 'react';
import classNames from 'classnames';

import { PureLink } from '@jsmdg/yoshi';
import styles from './LinkMenuItem.module.scss';

type LinkMenuItemProps = {
    readonly url: string;
    readonly label: string;
    readonly target?: string;
    readonly rel?: string;
};

const UnmemoizedLinkMenuItem = ({
    label,
    rel,
    target = '_self',
    url,
}: LinkMenuItemProps): JSX.Element => (
    <PureLink
        internal
        className={classNames('d-flex align-items-center', styles.textButton)}
        href={url}
        target={target}
        rel={rel}
    >
        {label}
    </PureLink>
);

export const LinkMenuItem = memo(UnmemoizedLinkMenuItem);
