import { type Dispatch, type ReactNode, type SetStateAction, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { ClickElement, GA4EventName, GA4FeatureCategory } from '@jsmdg/tracking';
import {
    BurgerMenuIcon,
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
    ChevronIcon,
    Link as ButtonLink,
    LinkVariant,
    RenderType,
    SidePanel,
    SlidePanelDirection,
} from '@jsmdg/yoshi';
import { type NavigationItem, type NavigationItemParent } from '../../../shared/types';
import {
    availableTrackingActions,
    getTrackingLabel,
    trackingCategories,
    trackNavigationClick,
} from '../../helper/trackNavigation';
import { a11yCommonMessages } from '../../i18n';
import { type HeaderContext } from '../../types';
import { BurgerNavigationGroup } from './BurgerNavigationGroup/BurgerNavigationGroup';
import { LinkNavigationGroup } from './LinkNavigationGroup';
import styles from './BurgerNavigation.module.scss';

const messages = defineMessages({
    openMenu: {
        defaultMessage: 'Hauptmenü öffnen',
    },
});

type Level = {
    level: NavigationItem[] | null | undefined;
    setLevel: Dispatch<SetStateAction<NavigationItem | null | undefined>>;
    label: ReactNode;
};

export const BurgerNavigation = ({
    layoutClassName,
}: {
    readonly layoutClassName?: string;
}): JSX.Element => {
    const Intl = useIntl();
    const { isMydays, topNavigationItems } = useFragmentContext<HeaderContext>();
    const [open, setOpen] = useState(false);
    const [activeLevel, setActiveLevel] = useState(0);
    const [secondLevel, setSecondLevel] = useState<NavigationItem | null | undefined>(null);
    const [thirdLevel, setThirdLevel] = useState<NavigationItem | null | undefined>(null);
    const [fourthLevel, setFourthLevel] = useState<NavigationItem | null | undefined>(null);

    const levels: Level[] = [
        {
            level: topNavigationItems,
            setLevel: setSecondLevel,
            label: secondLevel?.title,
        },
        {
            level: secondLevel?.children,
            setLevel: setThirdLevel,
            label: thirdLevel?.title,
        },
        {
            level: thirdLevel?.children,
            setLevel: setFourthLevel,
            label: fourthLevel?.title,
        },
        {
            level: fourthLevel?.children,
            setLevel: () => null,
            label: null,
        },
    ];

    const toggleLevel = (numLevel: number, itemId?: string): void => {
        const { label, level, setLevel } = levels[numLevel];

        if (itemId) {
            const selectedItem = level?.find(item => item.id === itemId);
            setLevel(selectedItem);
            setActiveLevel(numLevel + 1);
            return;
        }

        setActiveLevel(numLevel);

        trackNavigationClick({
            e: undefined,
            label: label as string,
            action: availableTrackingActions.BACK,
            category: trackingCategories.BURGER_NAVI,
            eventData: {
                eventName: GA4EventName.CloseNavigationItem,
                feature_category: GA4FeatureCategory.BurgerNavigation,
                click_element: label as string,
                click_text: 'Zurück',
                navigationlevel: numLevel,
            },
        });
    };

    const openMenu = (e?: React.MouseEvent): void => {
        setOpen(true);
        trackNavigationClick({
            e,
            label: 'Open',
            action: availableTrackingActions.BUTTON_CLICK,
            category: trackingCategories.BURGER_NAVI,
            eventData: {
                eventName: GA4EventName.ClickButton,
                feature_category: GA4FeatureCategory.BurgerNavigation,
                click_element: ClickElement.OpenBurgerNavigation,
            },
        });
    };

    const handleKeyPress = (e: React.KeyboardEvent): void => {
        if (e.key === 'Enter') {
            openMenu();
        }
    };

    const onRequestClose = (
        event?: React.UIEvent | React.KeyboardEvent | React.MouseEvent,
    ): void => {
        if (event?.type === 'click') {
            trackNavigationClick({
                e: event,
                label: 'CloseX',
                action: availableTrackingActions.BUTTON_CLICK,
                category: trackingCategories.BURGER_NAVI,
                eventData: {
                    eventName: GA4EventName.ClickButton,
                    feature_category: GA4FeatureCategory.BurgerNavigation,
                    click_element: ClickElement.CloseBurgerNavigation,
                },
            });
        }

        setOpen(false);
        setTimeout(() => {
            setActiveLevel(0);
        }, 400); // time of the transition animation
    };

    return (
        <div className={classNames(styles.mobileNav, layoutClassName)}>
            <Button
                size={ButtonSize.Responsive}
                variant={ButtonVariant.Ghost}
                color={ButtonColor.Dark}
                shape={ButtonShape.Icon}
                tabIndex={-1}
                onClick={openMenu}
                onKeyDown={handleKeyPress}
                a11yText={Intl.formatMessage(messages.openMenu)}
            >
                <BurgerMenuIcon />
            </Button>
            <SidePanel
                open={open}
                onRequestClose={onRequestClose}
                direction={SlidePanelDirection.Left}
                a11yCloseText={Intl.formatMessage(a11yCommonMessages.a11yCloseText)}
                headerElements={
                    activeLevel > 0 && (
                        <ButtonLink
                            as={RenderType.Button}
                            variant={LinkVariant.GreyQuiet}
                            onClick={() => toggleLevel(activeLevel - 1)}
                            onKeyDown={() => toggleLevel(activeLevel - 1)}
                            tabIndex={0}
                            className={classNames('d-flex align-items-center p-0')}
                            iconLeft={<ChevronIcon className={styles.chevronToLeft} />}
                        >
                            <FormattedMessage defaultMessage="Zurück" />
                        </ButtonLink>
                    )
                }
                fullWidth
            >
                <div className={classNames('w-100 h-100', styles.burgerNavigationWindow)}>
                    <div
                        className={classNames(styles.burgerNavigationContent, styles.panelOne, {
                            [styles.firstTranslateForward]: activeLevel > 0,
                        })}
                    >
                        <BurgerNavigationGroup
                            items={topNavigationItems}
                            level={0}
                            parent={{ id: secondLevel?.id } as NavigationItemParent}
                            toggleFunction={toggleLevel}
                            className="container border-bottom"
                        />
                        <LinkNavigationGroup />
                    </div>
                    <div
                        className={classNames(styles.burgerNavigationContent, styles.panelTwo, {
                            [styles.firstTranslateForward]: activeLevel === 1,
                            [styles.secondTranslateForward]: activeLevel === 2,
                        })}
                    >
                        <div className={styles.panelTwoInner}>
                            {secondLevel && (
                                <BurgerNavigationGroup
                                    items={secondLevel.children}
                                    level={1}
                                    parent={
                                        { ...secondLevel, children: [] } as NavigationItemParent
                                    }
                                    toggleFunction={toggleLevel}
                                    className="container border-bottom"
                                    parentTrackingLabel={secondLevel.title as string}
                                />
                            )}
                            <LinkNavigationGroup />
                        </div>
                    </div>
                    <div
                        className={classNames(styles.burgerNavigationContent, styles.panelThree, {
                            [styles.firstTranslateForward]: activeLevel === 2,
                            [styles.secondTranslateForward]: activeLevel === 3,
                        })}
                    >
                        <div className={styles.panelThreeInner}>
                            {thirdLevel && (
                                <BurgerNavigationGroup
                                    items={thirdLevel.children}
                                    level={2}
                                    parent={{ ...thirdLevel, children: [] } as NavigationItemParent}
                                    toggleFunction={toggleLevel}
                                    className="container border-bottom"
                                    parentTrackingLabel={getTrackingLabel(
                                        thirdLevel?.title as string,
                                        secondLevel?.title as string,
                                    )}
                                />
                            )}
                            <LinkNavigationGroup />
                        </div>
                    </div>
                    {isMydays && (
                        <div
                            className={classNames(
                                styles.burgerNavigationContent,
                                styles.panelFour,
                                {
                                    [styles.firstTranslateForward]: activeLevel === 3,
                                },
                            )}
                        >
                            <div className={styles.panelThreeInner}>
                                {fourthLevel && (
                                    <BurgerNavigationGroup
                                        items={fourthLevel.children}
                                        level={3}
                                        parent={
                                            { ...fourthLevel, children: [] } as NavigationItemParent
                                        }
                                        toggleFunction={toggleLevel}
                                        className="container border-bottom"
                                        parentTrackingLabel={getTrackingLabel(
                                            fourthLevel.title as string,
                                            getTrackingLabel(
                                                thirdLevel?.title as string,
                                                secondLevel?.title as string,
                                            ),
                                        )}
                                    />
                                )}
                                <LinkNavigationGroup />
                            </div>
                        </div>
                    )}
                </div>
            </SidePanel>
        </div>
    );
};

// eslint-disable-next-line import/no-default-export
export default BurgerNavigation;
