import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type HeaderContext } from '../../../types';
import { LinkMenuItem } from '../LinkMenuItem';
import styles from './LinkNavigationGroup.module.scss';

const messages = defineMessages({
    redeemVoucher: {
        defaultMessage: 'Gutschein einlösen',
    },
    newsletter: {
        defaultMessage: 'Newsletter',
    },
    faq: {
        defaultMessage: 'FAQ',
    },
    magazine: {
        defaultMessage: 'Magazin',
    },
    partnerPortal: {
        defaultMessage: 'Erlebnispartner werden',
    },
});

const LinkNavigationGroup = (): JSX.Element => {
    const intl = useIntl();

    const { featureFlagsVariation, tenantConfig }: HeaderContext = useFragmentContext();
    const oneVoucherRedemption = featureFlagsVariation?.redemption_entry_visits === 'on';
    const redemptionUrl = oneVoucherRedemption ? `/einloesen` : tenantConfig.urls.redemption;

    return (
        <div className={classNames(styles.linkNavigationGroup, 'container pb-6x')}>
            <LinkMenuItem label={intl.formatMessage(messages.redeemVoucher)} url={redemptionUrl} />
            <LinkMenuItem
                label={intl.formatMessage(messages.newsletter)}
                url={tenantConfig.urls.newsletter}
            />
            <LinkMenuItem label={intl.formatMessage(messages.faq)} url={tenantConfig.urls.faq} />
            <LinkMenuItem
                label={intl.formatMessage(messages.magazine)}
                url={tenantConfig.urls.magazine}
            />
            <LinkMenuItem
                label={intl.formatMessage(messages.partnerPortal)}
                url={tenantConfig.partnerPortalUrl}
            />
        </div>
    );
};

export { LinkNavigationGroup };
